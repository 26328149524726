import React from "react"

import { Transformation } from "cloudinary-react"
import { Link } from "gatsby"
import { Image } from "../Core"

import ImageCaption from "./ImageCaption"

function SingleSlide(props) {
  let title = props.title
  if (title.charAt(0) !== "/") title = "/" + title

  return (
    <div>
      <Link className="carousel-image-link" to={title.toLowerCase()}>
        <Image
          publicId={props.thumbnailPublicId}
          responsiveUseBreakpoints="true">
          <Transformation
            quality="auto"
            fetchFormat="auto"
            crop="scale"
            height="419"
          />
        </Image>
        <ImageCaption
          reviewerName={props.reviewerName}
          language={props.language}
          procedure={
            props.otherProcedureName
              ? props.otherProcedureName
              : props.procedure
          }
          monthYear={props.monthYear}
        />
      </Link>
    </div>
  )
}

export default SingleSlide
